import React, { FunctionComponent } from 'react';
import { Image, ResponsiveImageType } from 'react-datocms';
import ReactMarkdown from 'react-markdown';

interface Props {
  content: React.ReactNode | string;
  imageData?: ResponsiveImageType;
  title: string;
  fullWidth?: boolean;
}

const ContentBlock: FunctionComponent<Props> = ({
  imageData,
  title,
  content,
  fullWidth = true,
}) => {
  return (
    <section className={`Content_Block ${fullWidth ? 'Full_Width' : ''}`}>
      <div className="Content_Block_Text">
        <h3>{title}</h3>
        {typeof content === 'string' ? <ReactMarkdown>{content}</ReactMarkdown> : <>{content}</>}
      </div>
      {imageData ? (
        <div className="Content_Block_Image">
          <Image data={imageData} />
        </div>
      ) : null}
    </section>
  );
};

export default ContentBlock;
