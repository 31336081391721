import React from 'react';
import Head from 'next/head';
import ContentBlock from '../components/ContentBlock/ContentBlock';
import Layout from '../components/Layout/Layout';

export default function Custom404Page() {
  return (
    <Layout title="404">
      <Head>
        <meta name="robots" content="noindex"></meta>
      </Head>
      <div className="page_404">
        <ContentBlock
          fullWidth={false}
          title=""
          imageData={null}
          content={
            <>
              <h3>Pagina niet gevonden</h3>
              <p>
                Ga naar{' '}
                <a title="homepage" href="/">
                  home
                </a>
              </p>
            </>
          }
        />
      </div>
    </Layout>
  );
}
